(function($, window, document) {
    "use strict";
    $(function() {
        //##Variables
        var $body = $('body'),
        	$header = $('.main_header'),
            $window = $(window),
            $doc = $(document),
            defaultEasing = [0.4, 0.0, 0.2, 1];
        //End Variables

        //Header scroll animation
        if ($body.hasClass('single-post')) {
            $header.addClass('is--sticky');	
        }else{
	        $doc.scroll(function() {
	            if ($doc.scrollTop() >= $header.outerHeight()) {
	                $header.addClass('is--sticky');
	            } else {
	                $header.removeClass('is--sticky');
	            }
	        });
        }
        /*$('.banner_slider .owl-carousel').owlCarousel({
		    animateOut: 'fadeOut',
		    animateIn: 'zoomIn',
		    items:1,
		    dots: false,
		    autoplay: true,
		    lazyLoad : true,
		    smartSpeed:450
		});*/
        $('.brochure_slider').owlCarousel({
		    loop:false,
		    dots: false,
		    margin:10,
		    nav:true,
		    responsive:{
		        0:{
		            items:1
		        },
		        767:{
		            items:2
		        }
		    }
		});
		$('.tabs__header li').on('click', function(e){
			var $currentClass = $(this).attr('class').split(/\s+/)[1];
			$('.tab').removeClass('is--active');
			$('.'+$currentClass).addClass('is--active');
		});
		$('.search_icon').on('click', function(e){
			e.preventDefault();
			$('.search_header').toggle('fast');
		});
		$('.close_popup').on('click', function(e){
			e.preventDefault();
            setCookie('cookiesLogin',1);
			$('.popup').toggle('fast');
		});

		function getCookie(name) {
          var value = "; " + document.cookie;
          var parts = value.split("; " + name + "=");
          if (parts.length == 2) return parts.pop().split(";").shift();
        }
        function setCookie(name,days) {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days*24*60*60*1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = name + "=" + expires + "; path=/";
        }
        $window.load(function() {
            var hasCookies = getCookie('cookiesLogin');
            if(hasCookies == undefined){
                $('.popup').toggle('fast');
            }
        });
    });
}(window.jQuery, window, document));
